import React, {useEffect, useMemo, useRef} from 'react';

// pages import
import Pages from '../pages';

// react router import
import {
	BrowserRouter as Router,
	Route,
	Routes as Switch,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
// others import
import ScrollToTop from "../layout/ScrollIntoView";
import {retrieveCollegiaAccessToken} from './Helpers';
import Request from "./Request";
import axios from "axios";
import {connect, useDispatch} from "react-redux";
import {getAvailableCountries, getTranslations} from "../actions/Languages";
import i18next from 'i18next';
import {UPDATE_PROFILE} from "../actions/Profile/types";
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import * as SentryBrowser from "@sentry/browser";

import {createBrowserHistory} from 'history';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Switch);

const history = createBrowserHistory();

if (process.env.REACT_APP_ENV === "production") {
	Sentry.init({
		dsn: "https://72d98a5b229a4d6b94a40abdf1107f34@o1171073.ingest.sentry.io/4504565544779776",
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				)
			}),
			new Sentry.Replay(),
		],
		beforeSend(event) {
			// Check if it is an exception, if so, show the report dialog
			// Note that this only will work in the renderer process, it's a noop on the main process
			if (event.exception) {
				Sentry.showReportDialog();
			}
			return event;
		},
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		release: "app@v1.33.2",
	});
}

/**
 * @returns {[]}
 */
function makeRoutes() {
	const authed = retrieveCollegiaAccessToken() ?? false;
	const IndexPage = authed ? Pages.MainPage : Pages.Login;

	const publicPaths = {
		"/": IndexPage,
		"/:token": IndexPage,
		"/home": IndexPage,
		"/index": IndexPage,
		"/homepage": IndexPage,
		"/login": IndexPage,
		"/login/:token": IndexPage,
		"/forgot-password": Pages.ForgotPassword,
		"/forgot-password/request-sent": Pages.PasswordRequestSent,
		"/reset-password/:token": Pages.ResetPassword,
		"/sign-up": Pages.SignUp,
		"/enrolled-by-employer": Pages.EnrolledByEmployer,
		"/select-service": Pages.SelectService,

		// set up pages.
		'/activation/:token': Pages.Activation,
		'/account-activated/:token': Pages.AccountActivated,
		"/activation/setup/:token": Pages.ActivationPage.SetUpPage,
		"/first-password": Pages.Profile.FirstPassword,
		"/opt-out/:token": Pages.OptOut,
		"/mandate-created-successfully": Pages.MandateCreated,
		'/account-deleted': Pages.AccountDeleted,
		"/invitation/:code": Pages.Invitation,
		"/403": Pages.Page_403,
	};

	const privatePaths = {

		"/main-page": Pages.MainPage,
		"/main-page/:token": Pages.MainPage,
		"/collegia-contribution": Pages.AccountContributions,
		"/contributions-history": Pages.ContributionsHistory,
		"/portfolio": Pages.Portfolio,
		"/upcoming-contributions": Pages.UpcomingTransactions,
		"/contributions-history/:code": Pages.ContributionsHistory,
		"/your-pension": Pages.YourPension,
		"/adjust-retirement-age": Pages.YourPensionConfigs.AdjustYourRetirementAge,
		"/understand-lifestyles": Pages.UnderstandLifestyles,
		"/state-pension": Pages.YourPensionConfigs.StatePension,

		"/auto-enrolment": Pages.AutoEnrolment,
		"/opt-out/employer/:code": Pages.OptOutEmployer,

		"/refer-employer": Pages.ReferEmployer,
		"/refer-employer-complete": Pages.ReferEmployerComplete,

		"/investment-approach": Pages.InvestmentApproach.List,
		"/investment-approach/details/:name": Pages.InvestmentApproach.Details,

		"/other-pensions/": Pages.OtherPensions.List,
		"/extra-contributions": Pages.ExtraContributions.ContributionsList,
		"/extra-contributions/new": Pages.ExtraContributions.InsertValue,
		"/extra-contributions/new/:step": Pages.ExtraContributions.InsertValue,
		"/add-contribution/monthly": Pages.ExtraContributions.MonthlyContribution,
		"/add-contribution/one-off": Pages.ExtraContributions.SelectBanks,
		"/contributions/success": Pages.ExtraContributions.ContributionsList,
		"/contributions/details/:id/:type": Pages.ExtraContributions.Details,
		"/confirm-direct-debit-cancellation/:id": Pages.ExtraContributions.ConfirmDirectDebitCancellation,
		"/confirm-monthly-contribution": Pages.ExtraContributions.ConfirmMonthlyContributions,

		"/my-account": Pages.Profile.MyAccount,
		"/my-account/personal-information": Pages.Profile.PersonalInformation,
		"/my-account/login-details": Pages.Profile.LoginDetails,
		"/my-account/tax-relief": Pages.Profile.TaxRelief,
		"/my-account/login-details/:operation": Pages.Profile.SendProfileChangeConfirmationCode,
		"/my-account/login-details/update/:code": Pages.Profile.UpdateLoginDetails,

		"/your-documents": Pages.Profile.YourDocuments,

		"/terms-and-conditions": Pages.TermsAndConditions,

		"/my-account/beneficiaries": Pages.Profile.Beneficiaries,

		"/close-account": Pages.CloseAccount,

		"/options/:id": Pages.Options,

		"/my-account/address": Pages.Profile.Address,

		"/logout": Pages.LogOut,

		"/active-pension/nin-address": Pages.PensionNinAndAddress,
		"/active-pension/activated": Pages.PensionActivated,

		"/confirm-your-email": Pages.ConfirmYourEmail,
		"/confirm-your-mobile": Pages.ConfirmYourMobile,

		"/authorise-connection/:code": Pages.Connections.AuthoriseConnection,

		"/software-connections": Pages.SoftwareConnections,

		"/ifa-connections": Pages.IFAConnections,

		"/inform-source-of-funds": Pages.InformYourSourceOfFunds,
		"/confirm-employment-status": Pages.ConfirmEmploymentStatus,

		"/allow-hmrc-past-employers-info": Pages.AllowHMRCPastEmployersInfo,
		"/allow-hmrc-residency-status": Pages.AllowHMRCResidencyStatusInfo,

		"/residency-status-accepted": Pages.ResidencyStatusAccepted,
	};

	const routes = [];

	routes.push(Object.entries(publicPaths).map(([path, component]) => (
			<Route
				key={path}
				path={path}
				element={React.createElement(component, {})}
				exact
			/>
		)
	));

	routes.push(
		Object.entries(privatePaths).map(([path, component]) => (
				<Route
					key={path}
					path={path}
					element={React.createElement(component, {})}
					authed={authed !== false}
					exact
				/>
			),
		),
	);

	return routes;
}

/**
 * @param profile_data
 * @param resources
 * @returns {JSX.Element}
 * @constructor
 */
const Routes = (
	{
		profile_data = {
			language: "en",
			my_ip: "",
		},
		resources = {},
	}
): JSX.Element => {
	useRef("route");
	useEffect(() => {
		i18next.init({
			resources: Object.keys(resources)?.length === 0 ?
				JSON.parse(localStorage.getItem("translations"))
				:
				resources,
			load: "currentOnly",
			lng: profile_data?.country_code?.toLowerCase() === "gb" ? "en" : profile_data?.country_code?.toLowerCase() ?? "en",
		});
		if (profile_data?.id) {
			SentryBrowser.setUser({
				id: profile_data.id,
				username: `${profile_data.forename} ${profile_data.middle_name} ${profile_data.surname}`,
				email: profile_data.email,
			});
		}
	}, [
		resources,
		profile_data,
	]);
	const dispatch = useDispatch();

	useMemo(() => {
		if (!retrieveCollegiaAccessToken() && process.env.REACT_APP_ENV === "development")
			axios.get(`https://api.ipify.org?format=json`)
				.then((response = {
					data: {
						ip: "",
					}
				}) => {
					if (profile_data.my_ip === "" || profile_data?.my_ip === undefined) {
						dispatch({
							type: UPDATE_PROFILE,
							profile_data: {
								...profile_data,
								...JSON.parse(localStorage.getItem("profile_data")),
								my_ip: response.data.ip,
							},
							profile_loading: false,
							loading: false,
						});
					}
					Request.post("individual/is_allowed", {
							my_ip: response.data.ip,
						})
						.catch(error => {
							dispatch({
								type: UPDATE_PROFILE,
								profile_loading: false,
								loading: false,
							});
							if (error?.response?.status === 403) {
								if (window.location.pathname !== "/403") {
									window.location.href = `/${error.response.status}`;
								}
							}
						});
				})
				.catch(error => {
					Request.get("individual/is_allowed")
						.catch(error => {
							dispatch({
								type: UPDATE_PROFILE,
								profile_loading: false,
								loading: false,
							});
							if (error?.response?.status === 403) {
								if (window.location.pathname !== "/403") {
									window.location.href = `/${error.response.status}`;
								}
							}
						});
				});
		// }
	},[
		profile_data,
		dispatch,
	]);

	return (
		<Router
			basename={"/"}
			history={history}
		>
			<ScrollToTop/>
			<SentryRoute>
				{makeRoutes()}
			</SentryRoute>
		</Router>
	);
};

const mapStateToProps = state => {
	return {
		...state.Profile,
		...state.Languages,
	};
}

const mapDispatchToProps = dispatch => ({
	getTranslations: dispatch(getTranslations()),
	getAvailableCountries: dispatch(getAvailableCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
