import React, {useEffect} from "react";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import {
	retrieveCollegiaAccessToken
} from "../../utils/Helpers";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {withTranslation} from 'react-i18next';
import {prepareEmandate} from "../../actions/ExtraContributions";
import {useLocation, useNavigate} from "react-router-dom";
import Loading from "../../components/Loading";
import Typography from "@mui/material/Typography";
/**
 * @param profile_data
 * @param contributions
 * @returns {JSX.Element}
 * @constructor
 */
const MonthlyContribution = (
	{
		profile_data = [],
		me = () => {},
		token = "",
		prepareEmandate = () => {},
		prepare_emandate_loading = false,
	}
): JSX.Element => {
	const location = useLocation();
	const navigate = useNavigate();
	const { state } = location;
	const { contributionValue, selectedDay, source_of_fund_id, is_other, name} = state;
	useEffect(() => {
		me();
	}, [me]);
	useEffect(() => {
		prepareEmandate({
			contributions: {
				collection_day: selectedDay ?? 1,
				contribution_value: contributionValue ?? 0,
				source_of_fund_id: source_of_fund_id,
				is_other: is_other,
				name: name
			}
		});
	}, [prepareEmandate, selectedDay, contributionValue, source_of_fund_id, is_other, name]);
	if (!retrieveCollegiaAccessToken()) {
		window.location = "/login";
	}
	return (
		<Wrapper
			history={navigate}
			textHeader={"Extra Contributions"}
			hideFooter={true}
		>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				md={12}
				xs={12}
				id={"extra-contributions-design"}
				container
				item
			>
				{
					token.length > 0 ?
						<Grid
							container
							justifyContent={"center"}
							direction={"column"}
							id={"fetching-data"}
							xs={12}
							sm={12}
							lg={12}
							md={12}
							alignContent={"center"}
							paddingTop={"60px"}
							item
						>
							<Typography
								variant={"h5"}
								align={"center"}
							>
								Fetching data complete. Now you are be redirect to your bank.
							</Typography>
						</Grid>
						: <Loading/>
				}
			</Grid>
		</Wrapper>
	);
};
const mapStateToProps = state => {
	const {
		Profile = [],
		ExtraContributions = [],
	} = state;
	return {
		...Profile,
		...ExtraContributions,
	};
};
/**
 * @param dispatch
 * @returns {{myProjections: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	prepareEmandate: (data: any[] = false) => dispatch(prepareEmandate(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MonthlyContribution));