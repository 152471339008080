import Grid from "@mui/material/Grid";
import React from "react";
import logo from "../../images/Logo azul.svg";
import {Typography} from "@mui/material";
import ReactFlagsSelect from "react-flags-select";
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";

/**
 * @param history
 * @param isLogin
 * @param available_countries
 * @param selected_country
 * @param setCountry
 * @returns {JSX.Element}
 * @constructor
 */
const DefaultHeader = (
	{
		history = () => {
		},
		isLogin = false,
		available_countries = [],
		selected_country = "GB",
		setCountry = (newCountry = "GB") => newCountry,
	}
): JSX.Element => (
	<Grid
		xl={12}
		lg={12}
		md={12}
		sm={12}
		xs={12}
		item
		container
		alignItems={"center"}
		className={"login-header"}
	>
		<img
			src={logo}
			alt={"Collegia logo."}
			onClick={() => history(retrieveCollegiaAccessToken() ? "/main-page" : "/login")}
			className={"logo"}
		/>
		{isLogin && (
			<Typography
				className={"header-text"}
				onClick={() => history("/login")}
			>
				Have an account? <span>Sign in</span>
			</Typography>
		)}
		{
			process.env.REACT_APP_ENV === "local" && (
				<ReactFlagsSelect
					selected={selected_country.toUpperCase()}
					onSelect={(code) => setCountry(code.toLowerCase())}
					countries={available_countries}
					placeholder={"Select your language"}
					id={"country-language-selector"}
				/>
			)
		}
		{!isLogin && (
			<Typography
				className={"header-text"}
				onClick={() => history("/enrolled-by-employer")}
			>
				New to Collegia? <span>Sign up </span>
			</Typography>
		)}
	</Grid>
);

export default DefaultHeader;
