import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/material/Menu';
import clsx from "clsx";
import logo from "../../images/Logo azul.svg";
import ReactFlagsSelect from "react-flags-select";
import {connect} from "react-redux";
import {setCountry} from "../../actions/Languages";

/**
 * @param individual_setup
 * @param drawer
 * @param handleDrawer
 * @param available_countries
 * @param selected_country
 * @param setCountry
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderAppBar = (
	{
		individual_setup = false,
		drawer = false,
		handleDrawer = () => {
		},
		available_countries = [],
		selected_country = "GB",
		setCountry = (newCountry = "GB") => newCountry,
	}
): JSX.Element => (
	<AppBar
		position="fixed"
		id={"menu"}
		className={clsx("app-bar", {
			"app-bar-shift": drawer,
		})}
	>
		<Toolbar>
			<Typography variant="h6" noWrap className={"title"}>
				<img
					className={'logo'}
					src={logo}
					alt={"Collegia logo."}
				/>
			</Typography>
			{
				!individual_setup && (
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="end"
						onClick={() => handleDrawer}
						className={clsx(drawer && 'hide')}
						disableTouchRipple
						disableFocusRipple
						disableRipple
					>
						<MenuIcon open={false}/>
					</IconButton>
				)
			}
			{
				process.env.REACT_APP_ENV === "local" && (
					<ReactFlagsSelect
						selected={selected_country.toUpperCase()}
						onSelect={(code) => {
							setCountry(code.toLowerCase());
						}}
						countries={available_countries}
						placeholder={"Select your language"}
						id={"country-language-selector"}
						customLabels={{"GB": "United Kingdom"}}
					/>
				)
			}
		</Toolbar>
	</AppBar>
);

const mapStateToProps = state => state.Languages;

const mapDispatchToProps = dispatch => ({
	setCountry: (newCountry = "GB") => dispatch(setCountry(newCountry, null, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAppBar);
