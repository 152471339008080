import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import StillNotThere from "../../images/newStillNotThere.svg";
import Minimum from "../../images/newMinimumTree.svg";
import Moderate from "../../images/newModerateTree.svg";
import Comfortable from "../../images/newComfortableTree.svg";
import ArrowIcon from "../../images/Arrow.svg";

/**
 * @param my_projections
 * @param t
 * @returns {JSX.Element}
 * @private
 */
function _switchBadges(
	my_projections = {
		badge: "",
	},
	t = key => ""
): JSX.Element {
	switch (my_projections.badge) {
		default:
			return (
				<img
					className={"badge-image"}
					src={StillNotThere}
					alt={"Still not there"}
				/>
			);
		case t("individual.lifestyle_ruler.minimum"):
			return (
				<img
					className={"badge-image"}
					src={Minimum}
					alt={"Minimum"}
				/>
			);
		case t("individual.lifestyle_ruler.moderate"):
			return (
				<img
					className={"badge-image"}
					src={Moderate}
					alt={"Moderate"}
				/>
			);
		case t("individual.lifestyle_ruler.comfortable"):
			return (
				<img
					className={"badge-image"}
					src={Comfortable}
					alt={"Comfortable"}
				/>
			);
	}
}

/**
 * @param my_projections
 * @param className
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ExpectedRetirementLifestyleCardMainPage = (
	{
		my_projections = {
			badge: "",
		},
		className = "",
		history = () => {
		},
		t = key => key,
	}
): JSX.Element => {
	const [text, setText] = useState("");

	useEffect(() => {
			switch (my_projections?.badge) {
				default:
					setText(t("individual.main_page.expected_retirment_styles.still_not_there.text"));
					break;
				case t("individual.lifestyle_ruler.minimum"):
					setText(t("individual.main_page.expected_retirment_styles.minimum.text"));
					break;
				case t("individual.lifestyle_ruler.moderate"):
					setText(t("individual.main_page.expected_retirment_styles.moderate.text"));
					break;
				case t("individual.lifestyle_ruler.comfortable"):
					setText(t("individual.main_page.expected_retirment_styles.comfortable.text"));
					break;
			}
	}, [
		setText,
		my_projections?.badge,
		t,
	]);

	return (
		<Grid
			xl={4.8}
			lg={5.6}
			md={12}
			sm={12}
			item
			container
			justifyContent={'center'}
			className={`expected-retirement-lifestyle-card ${className}`}
			onClick={() => history("/understand-lifestyles")}
		>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					align={"center"}
					className={"title"}
				>
					{t("individual.main_page.expected_retirment_styles.title")}
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
				textAlign={"center"}
			>
				{_switchBadges(my_projections ?? {badge: ""}, t)}
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					align={"center"}
					className={"value"}
				>
					£ {my_projections?.formatted_total_yearly_income ?? 0} <span className={"year"}> / {t("individual.year")} </span>
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					align={"center"}
					className={"lifestyle"}
				>
					{
						my_projections?.badge !== t("individual.lifestyle_ruler.still_not_there") ?
							t("individual.main_page.expected_retirment_styles.current_life_style", {
								style: my_projections?.badge,
							})
							:
							my_projections?.badge
					}
				</Typography>
				<Typography
					align={"center"}
					className={"sub-text"}
				>
					{text}
				</Typography>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				xs={12}
				md={12}
				item
			>
				<Typography
					className={"cta"}
					align={"center"}
				>
					{t("individual.learn_more")}
					<img
						className={"icon-arrow"}
						src={ArrowIcon}
						alt={t("individual.learn_more")}
					/>
				</Typography>
			</Grid>
		</Grid>
	)
};

export default ExpectedRetirementLifestyleCardMainPage;
