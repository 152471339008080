import React from "react";
// importing material ui icons.
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
// importing the default images.
import logo from "../../../images/Logo azul.svg";
import icon from "../../../images/Grupo 2.svg";
import arrow from "../../../images/arrow-down-culture.svg";
import {connect} from "react-redux";
import Divider from "@mui/material/Divider";
import ReactFlagsSelect from "react-flags-select";

/**
 * @param profile_data
 * @param drawer
 * @param history
 * @param handleDrawerOpen
 * @param available_countries
 * @param selected_country
 * @param setCountry
 * @param textHeader
 * @param t
 * @returns {React.JSX.Element}
 */
const appbar = (
	{
		profile_data = [],
		drawer = false,
		history = {},
		handleDrawerOpen = () => {
		},
		available_countries = [],
		selected_country = "GB",
		setCountry = (newCountry = "GB") => newCountry,
		textHeader = "",
		t = key => key,
		removeRightMenu = false,
	}
): JSX.Element => (
	<AppBar
		position={"static"}
		id={"menu-logged"}
		className={clsx("app-bar", {
			"app-bar-shift": drawer,
		})}
	>
		<Divider orientation={'horizontal'} flexItem variant={'fullWidth'}/>
		<Toolbar>
			<Grid
				xs={12}
				md={12}
				lg={12}
				sm={12}
				xl={12}
				alignItems={"center"}
				justifyContent={"space-between"}
				direction={"row"}
				item
				container
			>
				<img
					onClick={() => history("/main-page")}
					src={logo}
					alt={"Collegia logo."}
					className={"logo-show"}
				/>
				<Typography
					onClick={() => history("/main-page")}
					className={"title-page"}
				>
					<img
						onClick={() => history("/main-page")}
						className={"img-mobile-arrow rotate"}
						src={arrow}
						alt={"Arrow Icon"}
					/>
					{textHeader}
				</Typography>
				{
					process.env.REACT_APP_ENV === "local" && (
						<Grid
							xl={true}
							lg={true}
							md={true}
							sm={true}
							xs={true}
							container
							justifyContent={"flex-end"}
							id={'language-selector'}
							item
						>
							<ReactFlagsSelect
								selected={selected_country?.toUpperCase()}
								onSelect={(code) => setCountry(code.toLowerCase(), profile_data.id, true)}
								countries={available_countries}
								placeholder={"Select your language"}
							/>
						</Grid>
					)
				}
				{
					!removeRightMenu && (
						<Grid
							xl={true}
							lg={true}
							md={true}
							sm={true}
							xs={true}
							container
							direction={"row"}
							justifyContent={"flex-end"}
							alignItems={"center"}
							onClick={() => handleDrawerOpen()}
							item
						>
							<Grid
								item
								paddingRight={"10px"}
								className={"cursor-pointer"}
							>
								<img
									src={icon}
									alt={"Person Icon"}
								/>
							</Grid>

							<Grid
								textAlign={'end'}
								item
								className={"app-bar-full-name"}
							>
								{
									profile_data ?
										<Typography
											className={"typography-name-style"}
											noWrap
										>
											{t("individual.main_page.salute_user", {
												username: profile_data?.forename + " " + profile_data?.surname
											})}
										</Typography>
										:
										""
								}
							</Grid>
							<Grid
								className={"cursor-pointer"}
								paddingLeft={"10px"}
							>
								<img
									className={'img-mobile-arrow'}
									src={arrow}
									alt={"Arrow Icon"}
								/>
							</Grid>
						</Grid>
					)
				}

			</Grid>
		</Toolbar>
		<Divider orientation={'horizontal'} flexItem variant={'fullWidth'}/>
	</AppBar>
);

export default connect(state => ({notifications: state.Notifications}))(appbar);
